<template>
  <div class="auth-layout">
    <router-view></router-view>
  </div>
</template>
<script setup>

</script>
<style scoped>

</style>