import {createRouter, createWebHistory} from 'vue-router'
import HomeLayout from "@/layouts/HomeLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";

const routes = [
    {
        name: 'Auth',
        path: '/auth',
        component: AuthLayout,
        children: [
            {
                path: '/auth',
                name: 'authPage',
                component: () => import('@/views/AuthPage')
            }
        ]
    },
    {
        name: 'Home',
        path: '/',
        component: HomeLayout,
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/HomeView.vue'),
                redirect: to => {
                    return { path: '/transactions' }
                },
            },
            {
                path: '/wallet-accounts',
                name: 'walletAccounts',
                component: () => import('@/views/WalletAccountsView.vue')
            },
            {
                path: '/categories',
                name: 'categories',
                component: () => import('@/views/CategoriesView.vue')
            },
            {
                path: '/transactions',
                name: 'transactions',
                component: () => import('@/views/TransactionsReportView.vue')
            },
            {
                path: '/categories-report',
                name: 'categories-report',
                component: () => import('@/views/CategoriesReportView.vue')
            },
            {
                path: '/report',
                name: 'report',
                component: () => import('@/views/ReportView.vue')
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
