import axios from 'axios';
import TokenService from "../services/TokenService";
import router from "@/router";
// export const baseURL = 'http://localhost:7272';
export const baseURL = 'http://onemoney.uz';
// export const baseURL = 'http://10.0.40.52:7272';

const http = axios.create({
    baseURL: baseURL + '/'
});

function setConfiguration(provider) {
    provider.interceptors.request.use(config => {
        let token = TokenService.getToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        config.headers['Accept'] = 'application/json';
        config.headers['Access-Control-Allow-Origin'] = '*';
        config.headers['Content-Type'] = 'application/json';
        return config;
    }, error => Promise.reject(error));

    provider.interceptors.response.use(
        res => res.data,
        error => {
            console.log(error.response.status, 'axios')
            if (error.response?.status == 401) {
                router.push({path: '/auth'});
            }
            return Promise.reject(error);
        }
    );
}

setConfiguration(http);

export default http;
