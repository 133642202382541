import http from "@/plugins/axios";
import moment from 'moment';


export default ({
    state: {
        isAddTransaction: false,
        isAddTransactionConfirm: false,
        selectedWalletAccount: null,
        selectedCategory: null,
        transaction: {
            id: 0,
            comment: null,
            amount: '',
            transactionDate: new Date(),
            walletAccountId: null,
            walletAccountIdToExchange: null,
            categoryId: null,
            transactionType: 0
        },
        isGetTransaction: false
    },
    getters: {},
    mutations: {
        changeSelectedWalletAccount(state, value) {
            state.selectedWalletAccount = value
        },
        changeSelectedCategory(state, value) {
            state.selectedCategory = value;
        },
        toggleMyAddTransaction(state) {
            state.isAddTransaction = !state.isAddTransaction;
            if (state.isAddTransactionConfirm) {
                state.isAddTransactionConfirm = false;
                state.isAddTransaction = false;
                state.transaction = {
                    id: 0,
                    comment: null,
                    amount: null,
                    transactionDate: new Date(),
                    walletAccountId: null,
                    walletAccountIdToExchange: null,
                    categoryId: null,
                    transactionType: 0
                };
            }
        },
        hideMyAddTransaction(state) {
            state.isAddTransaction = false;
        },
        showMyAddTransactionConfirm(state) {
            state.isAddTransactionConfirm = true;
        },
        hideMyAddTransactionConfirm(state) {
            state.isAddTransactionConfirm = false;
        },
        changeTransaction(state, data) {
            state.transaction = data;
        },
        changeIsGetTransaction(state, value) {
            state.isGetTransaction = value;
        }
    },
    actions: {}
})