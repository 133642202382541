import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueDatePicker from '@vuepic/vue-datepicker';
import moment from 'moment';


import '@vuepic/vue-datepicker/dist/main.css'
import './assets/css/styles.css';
import './assets/css/responsive.css';
import '@mdi/font/css/materialdesignicons.css';

// Vuetify
import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'


const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'light'
    }
})




createApp(App)
    .use(vuetify)
    .use(router)
    .use(store)
    .use(moment)
    .component('VueDatePicker', VueDatePicker)
    .mount('#app')

