import { createStore } from 'vuex';
import requests from './requests';
import transaction from './transaction'

export default createStore({
  state: {
    isNotificationOpen: false,
    isNotificationType: '',
    isNotificationText: '',
    myHomeLayoutLoading: false,
    walletAccounts: [],
    categories: [],
  },
  getters: {
    walletAccounts(state) {
      return state.walletAccounts;
    },
    incomeCategories(state) {
      return state.categories.filter(x => x.isCredit);
    },
    outcomeCategories(state) {
      return state.categories.filter(x => !x.isCredit);
    },
    getWalletAccountById: (state) => (id) => {
      let item = state.walletAccounts.find(x => x.id == id);
      return item ? item : {};
    },
    getCategoryById: (state) => (id) => {
      let item = state.categories.find(x => x.id == id);
      return item ? item : {};
    },
    months(state) {
      return [
        {
          name: 'Январь',
          value: 1
        },
        {
          name: 'Февраль',
          value: 2
        },
        {
          name: 'Март',
          value: 3
        },
        {
          name: 'Апрель',
          value: 4
        },
        {
          name: 'Май',
          value: 5
        },
        {
          name: 'Июнь',
          value: 6
        },
        {
          name: 'Июль',
          value: 7
        },
        {
          name: 'Август',
          value: 8
        },
        {
          name: 'Сентябрь',
          value: 9
        },
        {
          name: 'Октябрь',
          value: 10
        },
        {
          name: 'Ноябрь',
          value: 11
        },
        {
          name: 'Декабрь',
          value: 12
        },
      ]
    },
    yearList() {
      let result = [];
      for (let year = 2022; year <= new Date().getFullYear(); year++) {
        result.push(year)
      }
      return result
    },
    getMonthByValue: (state, getters) => (value) => {
      let item = getters.months.find(x => x.value == value);
      return item ? item : {};
    },
    transactionTypes() {
      return [
        {
          name: 'Доход',
          value: 0
        },
        {
          name: 'Расход',
          value: 1
        },
        {
          name: 'Перевод',
          value: 2
        },
      ]
    }

  },
  mutations: {
    toggleNotification(state, value) {
      state.isNotificationOpen = value
    },
    setNotificationType(state, value) {
      state.isNotificationType = value;
    },
    setNotificationText(state, value) {
      state.isNotificationText = value;
    },
    notificationSuccess(state, text) {
      state.isNotificationOpen = true;
      state.isNotificationType = 'success';
      state.isNotificationText = text;
    },
    notificationError(state, text) {
      state.isNotificationOpen = true;
      state.isNotificationType = 'error';
      state.isNotificationText = text;
    },
    notificationInfo(state, text) {
      state.isNotificationOpen = true;
      state.isNotificationType = 'info';
      state.isNotificationText = text;
    },
    showMyHomeLayoutLoading(state) {
      state.myHomeLayoutLoading = true;
    },
    hideMyHomeLayoutLoading(state) {
      state.myHomeLayoutLoading = false;
    },
    setWalletAccounts(state, data) {
      state.walletAccounts = data;
    },
    setCategories(state, data) {
      state.categories = data;
    }
  },
  actions: {
  },
  modules: {
    transaction,
    requests
  }
})
