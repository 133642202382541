import http from "@/plugins/axios";
export default ({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        async login(context, request) {
            return await http.post('/api/AuthUser/login', request);
        },
        async register(context, request) {
            return await http.post('/api/AuthUser/createUser', request);
        },
        // WalletAccount
        async upsertWalletAccount(context, request) {
            return await http.post('/api/WalletAccount/upsert', request);
        },
        async getWalletAccounts(context) {
            return await http.get('/api/WalletAccount');
        },
        async getWalletAccount(context, id){
            return await http.get(`/api/WalletAccount/${id}`);
        },
        async deleteWalletAccount(context, id){
            return await http.delete(`/api/WalletAccount/${id}`);
        },
        // Categories
        async upsertCategory(context, request) {
            return await http.post('/api/Category/upsert', request);
        },
        async getCategories(context) {
            return await http.get('/api/Category')
        },
        async getCategory(context, id){
            return await http.get(`/api/Category/${id}`);
        },
        async deleteCategory(context, id){
            return await http.delete(`/api/Category/${id}`);
        },
        async getCategoriesForReport(context, request) {
            return await http.post('/api/Category/getUserCategoriesForReport', request)
        },
        async getCategoriesForPercentage(context, request) {
            return await http.post('/api/Category/getUserCategoriesForProgress', request)
        },
        // Transaction
        async upsertTransaction(context, request) {
            return await http.post('/api/Transaction/upsert', request);
        },
        async getUserTransactionsForReport(context, request) {
            return await http.post('/api/Transaction/getUserTransactionsForReport', request.value);
        },
        async getUserTransaction(context, id) {
            return await http.get(`/api/Transaction/${id}`);
        },
        async deleteUserTransaction(context, id) {
            return await http.delete(`/api/Transaction/${id}`);
        }
    },
})
