<template>
  <v-navigation-drawer
      class="my-navigation-drawer"
      v-model="valueDrawer"
      location="bottom"
      :temporary="true"
  >
    <div class="my-add-transaction-content">
      <v-row justify="center">
        <v-col cols="12" md="6" xl="4">
          <div v-if="!!selectedWalletAccount" class="my-add-transaction-content__header d-flex align-center justify-space-between pt-3 pb-3">
            <div class="my-add-transaction-content__header-left d-flex align-center">
              <v-icon class="mr-3">{{ selectedWalletAccount.icon}}</v-icon>
              <h4>{{ selectedWalletAccount.name}}</h4>
            </div>
            <div class="my-add-transaction-content__header-right">
              <v-icon>mdi-star</v-icon>
            </div>
          </div>
          <v-tabs
              v-model="tab"
              bg-color="teal-darken-4"
          >
            <v-tab value="one">Расход</v-tab>
            <v-tab value="two">Доход</v-tab>
            <v-tab value="three">Перевод</v-tab>
          </v-tabs>

          <v-window v-model="tab">
            <v-window-item value="one">
              <div class="selection-icons-wrapper">
                <ul>
                  <li v-for="(item, itemIndex) in outcomeCategories"
                      @click="selectCategory(item)"
                      :key="itemIndex">
                    <span>{{ item.name }}</span>
                    <div class="selection-icons-item-icon" :style="`background: ${item.bgColor}`">
                      <v-icon>{{ item.icon }}</v-icon>
                    </div>
                  </li>
                </ul>
              </div>
            </v-window-item>

            <v-window-item value="two">
              <div class="selection-icons-wrapper">
                <ul>
                  <li v-for="(item, itemIndex) in incomeCategories"
                      @click="selectCategory(item)"
                      :key="itemIndex">
                    <span>{{ item.name }}</span>
                    <div class="selection-icons-item-icon" :style="`background: ${item.bgColor}`">
                      <v-icon>{{ item.icon }}</v-icon>
                    </div>
                  </li>
                </ul>
              </div>
            </v-window-item>

            <v-window-item value="three">
              <div class="selection-icons-wrapper">
                <ul v-if="walletAccounts && walletAccounts.length > 0">
                  <li v-for="(item, itemIndex) in walletAccounts.filter(x => x.id !== selectedWalletAccount.id)"
                      @click="selectCategory(item)"
                      :key="itemIndex">
                    <span>{{ item.name }}</span>
                    <div class="selection-icons-item-icon" :style="`background: ${item.bgColor}`">
                      <v-icon>{{ item.icon }}</v-icon>
                    </div>
                  </li>
                </ul>
              </div>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
    </div>
  </v-navigation-drawer>
</template>

<script setup>
import {ref, computed} from "vue";
import {useStore} from "vuex";

const store = useStore();

const tab = ref(null);

const valueDrawer = computed(() => {
  return store.state.transaction.isAddTransaction;
});

const selectedWalletAccount = computed(() => store.state.transaction.selectedWalletAccount);
const incomeCategories = computed(() => store.getters["incomeCategories"]);
const outcomeCategories = computed(() => store.getters["outcomeCategories"]);
const walletAccounts = computed(() => store.getters["walletAccounts"]);


function selectCategory(item) {
  store.commit('changeSelectedCategory', item);
  store.commit('hideMyAddTransaction');
  store.commit('showMyAddTransactionConfirm');
}

</script>

<style scoped>

</style>