<template>
  <v-navigation-drawer
      class="my-navigation-drawer"
      v-model="valueDrawer"
      :scrim="false"
      :sticky="true"
      location="bottom"
      :touchless="true"
  >
    <div class="my-add-transaction-confirm-content">
      <v-row justify="center">
        <v-col cols="12" md="6" xl="4">
          <div class="my-add-transaction-confirm-header d-flex">
            <div
                v-if="!!selectedWalletAccount"
                id="menu-first"
                :style="`background: ${selectedWalletAccount.bgColor}`"
                class="my-add-transaction-confirm-header__item">
              <span>Со счёт</span>
              <p>{{ selectedWalletAccount.name }}</p>

              <div class="my-add-transaction-confirm-header__left-icon">
                <v-icon :style="`color: ${selectedWalletAccount.bgColor}`">{{ selectedWalletAccount.icon }}</v-icon>
              </div>

              <v-menu class="my-add-transaction-confirm-header__item-menu menu-first" activator="#menu-first">
                <v-list>
                  <v-list-item
                      v-for="(item, index) in walletAccounts"
                      :key="index"
                      :value="index"
                      @click="store.commit('changeSelectedWalletAccount', item)"
                  >
                    <v-list-item-title>
                      <div class="d-flex justify-space-between align-center text-white h-100 px-2"
                           :style="`background: ${item.bgColor}`">
                        <p>{{ item.name }}</p>
                        <v-icon>{{ item.icon }}</v-icon>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div
                v-if="!!selectedCategory"
                id="menu-second"
                :style="`background: ${selectedCategory.bgColor}`"
                class="my-add-transaction-confirm-header__item">
              <span>На</span>
              <p>{{ selectedCategory.name }}</p>

              <div class="my-add-transaction-confirm-header__left-icon">
                <v-icon :style="`color: ${selectedCategory.bgColor}`">{{ selectedCategory.icon }}</v-icon>
              </div>

              <v-menu class="my-add-transaction-confirm-header__item-menu" activator="#menu-second">
                <v-list>
                  <v-list-item
                      v-for="(item, index) in walletAccounts"
                      :key="index"
                      :value="index"
                      @click="store.commit('changeSelectedCategory', item)"
                  >
                    <v-list-item-title>
                      <div class="d-flex justify-space-between align-center text-white h-100 px-2"
                           :style="`background: ${item.bgColor}`">
                        <p>{{ item.name }}</p>
                        <v-icon>{{ item.icon }}</v-icon>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                      v-for="(item, index) in categories"
                      :key="index"
                      :value="index"
                      @click="store.commit('changeSelectedCategory', item)"
                  >
                    <v-list-item-title>
                      <div class="d-flex justify-space-between align-center text-white h-100 px-2"
                           :style="`background: ${item.bgColor}`">
                        <p>{{ item.name }}</p>
                        <v-icon>{{ item.icon }}</v-icon>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="my-add-transaction-confirm-inputs">
            <v-text-field
                id="moneyInput"
                v-model="moneyInput"
                @keyup.enter="addTransaction"
                placeholder="Сумма"
                variant="plain"
                hide-details
                type="text"
            ></v-text-field>
            <v-text-field
                v-model="transaction.comment"
                placeholder="Комментарии"
                @keyup.enter="addTransaction"
                variant="plain"
            ></v-text-field>
            <VueDatePicker v-model="transaction.transactionDate"
                           placeholder="Date"
                           auto-apply
                           time-picker-inline
                           :dark="$vuetify.theme.current.dark"
                           :teleport="true"
                           :format="'dd/MM/yyyy HH:mm'"
                           position="center"/>
            <v-btn
                @click="addTransaction"
                :loading="addLoadingBtn"
                class="w-100 mt-3"
                variant="flat"
                color="teal-darken-4">Сохранить
            </v-btn>
            <v-btn
                v-if="transaction.id"
                @click="deleteTransaction"
                :loading="deleteLoadingBtn"
                class="w-100 mt-3"
                variant="flat"
                color="red-darken-4">Удалить
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-navigation-drawer>
</template>
<script setup>
import {computed, ref, watch} from "vue";
import {useStore} from "vuex";
import {useTransaction} from "@/logics/transaction";
import moment from "moment";

const {setTransaction, resetTransaction} = useTransaction();

const store = useStore();

const addLoadingBtn = ref(false);
const deleteLoadingBtn = ref(false);

const valueDrawer = computed(() => store.state.transaction.isAddTransactionConfirm);
const selectedWalletAccount = computed(() => store.state.transaction.selectedWalletAccount);
const selectedCategory = computed(() => store.state.transaction.selectedCategory);
const walletAccounts = computed(() => store.state.walletAccounts);
const categories = computed(() => store.state.categories);
const transaction = computed({
  get() {
    return store.state.transaction.transaction;
  },
  set(newValue) {
    store.commit('changeTransaction', newValue)
  }
})

const moneyInput = computed({
  get() {
    return formatMoney(store.state.transaction.transaction.amount);
  },
  set(newValue) {
    transaction.value.amount = formatMoney(newValue);
  }
})

const formatMoney = (value) => {
  value = String(value).replace(/[^0-9.]/g, '');
  const parts = value.split('.');
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.length > 1 ? `${integerPart}.${parts[1]}` : integerPart;
};

async function addTransaction() {
  setTransaction();
  try {
    addLoadingBtn.value = true;
    transaction.value.amount = Number(String(transaction.value.amount).replace(/[^0-9.]/g, ''));
    // Format the transaction date
    if (transaction.value.transactionDate) {
      transaction.value.transactionDate = moment(transaction.value.transactionDate).format('YYYY-MM-DDTHH:mm:ssZ');
    }
    const res = await store.dispatch('upsertTransaction', transaction.value);
    if (res.success) {
      store.commit('hideMyAddTransactionConfirm');
      resetTransaction();
      store.commit('notificationSuccess', 'Успешно');
      store.commit('changeIsGetTransaction', true);
      await getWalletAccounts();
    }
  } catch (e) {
    store.commit('notificationError', e.response?.data?.error?.message)
  } finally {
    addLoadingBtn.value = false;
  }
}

async function deleteTransaction() {
  if (confirm("Точно, вы хотите удалить?")) {
    try {
      deleteLoadingBtn.value = true;
      const res = await store.dispatch('deleteUserTransaction', transaction.value.id);
      if (res.success) {
        store.commit('hideMyAddTransactionConfirm');
        resetTransaction();
        store.commit('notificationSuccess', 'Успешно');
        store.commit('changeIsGetTransaction', true);
        await getWalletAccounts();
      }
    } catch (e) {
      store.commit('notificationError', e.response?.data?.error?.message)
    } finally {
      deleteLoadingBtn.value = false;
    }
  }
}

async function getWalletAccounts() {
  try {
    const res = await store.dispatch('getWalletAccounts');
    if (res.success) {
      store.commit('setWalletAccounts', res.result);
      store.commit('changeSelectedWalletAccount', store.state.walletAccounts[0]);
    }
  } catch (e) {
    store.commit('notificationError', e.response?.data?.error?.message)
  }
}

watch(() => transaction.value, (newVal, oldVal) => {
  store.commit('changeTransaction', newVal)
}, {deep: true});

watch(() => valueDrawer.value, (newValue, oldValue) => {
  const inputElement = document.getElementById('moneyInput');
  if (inputElement) {
    inputElement.focus();
  }
});
</script>

<style scoped>

</style>