<template>
  <div class="my-notification-wrapper">
    <v-snackbar
        v-model="isOpen"
        :timeout="6000"
        :value="true"
        :position="'fixed'"
        :color="`${notificationType}`"
        class="notification-snackbar"
        max-width="300px"
        location="top right"
    >
      {{ store.state.isNotificationText }}
      <template v-slot:actions>
        <v-icon @click="isOpen = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>
<script setup>
import {ref, computed } from "vue";
import {useStore} from "vuex";


const store = useStore();

const isOpen = computed( {
  get() {
    return store.state.isNotificationOpen
  },
  set(value) {
    store.commit('toggleNotification', value);
    if (!value) {
      store.commit('setNotificationType', '');
      store.commit('setNotificationText', '');
    }
  }
});



const notificationType = computed(() => {
  const type = store.state.isNotificationType;
  switch(type) {
    case 'error':
      return 'deep-orange-accent-4'
    case 'success':
      return 'green accent-3'
    case 'info':
      return 'blue-accent-4'
    default:
      return 'grey-darken-3'
  }
});








</script>
<style scoped>

</style>