<template>
  <v-card height="100vh">
    <v-layout class="w-100 h-100">
      <v-app-bar
          :theme="$vuetify.theme.currentTheme"
          prominent
      >
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer">
          <v-icon
              size="large"
          ></v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title>
          <v-btn variant="flat" color="teal-darken-4" @click="reLoadPage()">OneMoney</v-btn>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn @click="toggleTheme" icon="mdi-theme-light-dark"></v-btn>
        <v-btn @click="logout" variant="text" icon="mdi-logout"></v-btn>
      </v-app-bar>

      <v-navigation-drawer
          v-model="drawer"
          location="left"
          :temporary="true"
      >
        <v-list>
          <v-list-item
              v-for="(item, i) in menuItems"
              :key="i"
              :value="item"
              :to="item.path"
          >
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <div class="my-content">
        <my-home-layout-loading v-if="myLoading" />
        <router-view></router-view>

        <div class="footer-menu-wrapper">
          <v-bottom-navigation
              v-model="valueFooter"
              color="teal"
              :grow="true"
          >
            <v-btn to="/wallet-accounts">
              <v-icon>mdi-credit-card-outline</v-icon>
              Счет
            </v-btn>

            <v-btn to="/categories-report">
              <v-icon>mdi-chart-pie-outline</v-icon>
              Категории
            </v-btn>

            <v-btn to="/transactions">
              <v-icon>mdi-list-box-outline</v-icon>
              Операции
            </v-btn>

            <v-btn to="/report">
              <v-icon>mdi-chart-timeline-variant</v-icon>
              Обзор
            </v-btn>
          </v-bottom-navigation>
          <div class="add-btn-wrapper" @click="toggleAddTransaction">
            <v-icon>mdi-plus</v-icon>
          </div>

          <my-add-transaction-dialog />
          <my-add-transaction-confirm-dialog />

        </div>
      </div>
    </v-layout>

  </v-card>
</template>

<script setup>
import {useTheme} from 'vuetify'
import {ref, computed, onMounted, watch} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import MyHomeLayoutLoading from "@/components/Basic/MyHomeLayoutLoading.vue";
import MyAddTransactionDialog from "@/components/Basic/MyAddTransactionDialog.vue";
import MyAddTransactionConfirmDialog from "@/components/Basic/MyAddTransactionConfirmDialog.vue";

const theme = useTheme();
const router = useRouter();
const store = useStore();
const drawer = ref(false);
const valueFooter = ref(1);
function toggleTheme() {
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark';
  localStorage.setItem('theme', theme.global.name.value);
}
function setTheme() {
  let themeFromLocalStorage = localStorage.getItem('theme');
  if (themeFromLocalStorage) {
    theme.global.name.value = themeFromLocalStorage;
  }
}
function logout() {
  localStorage.clear();
  router.push({path: '/auth'})
}




function toggleAddTransaction() {
  store.commit('toggleMyAddTransaction');
}

const myLoading = computed(() => {
  return store.state.myHomeLayoutLoading;
})

const menuItems = computed(() => {
  return [
    {
      path: '/wallet-accounts',
      title: 'Счеты',
    },
    {
      path: '/categories',
      title: 'Категории',
    },
  ]
})

async function getWalletAccounts() {
  try {
    const res = await store.dispatch('getWalletAccounts');
    if (res.success) {
      store.commit('setWalletAccounts', res.result);
      store.commit('changeSelectedWalletAccount', store.state.walletAccounts[0])
    }
  } catch (e) {
    store.commit('notificationError', e.response?.data?.error?.message)
  }
}

async function getCategories() {
  try {
    const res = await store.dispatch('getCategories');
    if (res.success) {
      store.commit('setCategories', res.result);
    }
  } catch (e) {
    store.commit('notificationError', e.response?.data?.error?.message)
  }
}

function reLoadPage() {
  router.go(router.currentRoute)
}

onMounted(() => {
  getWalletAccounts();
  getCategories();
  setTheme();
})

</script>