import {ref, computed} from 'vue';
import moment from "moment";
import store from '@/store/index';


export function useTransaction() {
    const transaction = ref({
        id: 0,
        comment: null,
        amount: null,
        transactionDate: new Date(),
        walletAccountId: null,
        walletAccountIdToExchange: null,
        categoryId: null,
        transactionType: 0
    });

    function setTransactionForEdit(data) {
        transaction.value = {
            id: data.id,
            comment: data.comment,
            amount: data.amount,
            transactionDate: data.transactionDate,
            walletAccountId: data.walletAccountId,
            walletAccountIdToExchange: data.walletAccountIdToExchange,
            categoryId: data.categoryId,
            transactionType: data.transactionType
        };

        store.commit('changeTransaction', transaction.value);

        if (data.walletAccountId) {
            let walletAccount = store.getters.getWalletAccountById(data.walletAccountId);
            if (walletAccount) {
                store.commit('changeSelectedWalletAccount', walletAccount);
            }
        }
        if (data.categoryId) {
            let category = store.getters.getCategoryById(data.categoryId);
            if (category) {
                store.commit('changeSelectedCategory', category);
            }
        }
        if (data.walletAccountIdToExchange) {
            let walletAccount = store.getters.getWalletAccountById(data.walletAccountIdToExchange);
            if (walletAccount) {
                store.commit('changeSelectedCategory', walletAccount);
            }
        }
    }

    function setTransaction() {
        transaction.value.id = store.state.transaction.transaction.id;
        transaction.value.amount = store.state.transaction.transaction.amount;
        transaction.value.comment = store.state.transaction.transaction.comment;
        transaction.value.transactionDate = moment(store.state.transaction.transaction.transactionDate).isValid() ?
            moment(store.state.transaction.transaction.transactionDate).format() : null;

        if (store.state.transaction.selectedWalletAccount) {
            transaction.value.walletAccountId = store.state.transaction.selectedWalletAccount.id;
        }

        if (store.state.transaction.selectedCategory) {
            if (Object.prototype.hasOwnProperty.call(store.state.transaction.selectedCategory, 'isCredit')) {
                transaction.value.categoryId = store.state.transaction.selectedCategory.id;
                transaction.value.transactionType = store.state.transaction.selectedCategory.isCredit ? 0 : 1;
                transaction.value.walletAccountIdToExchange = null;
            } else {
                transaction.value.categoryId = null;
                transaction.value.transactionType = 2;
                transaction.value.walletAccountIdToExchange = store.state.transaction.selectedCategory.id;
            }
        }
        store.commit('changeTransaction', transaction.value);
    }

    function resetTransaction() {
        transaction.value = {
            id: 0,
            comment: null,
            amount: null,
            transactionDate: new Date(),
            walletAccountId: null,
            walletAccountIdToExchange: null,
            categoryId: null,
            transactionType: 0
        }
        store.commit('changeTransaction', transaction.value);
        store.commit('changeSelectedCategory', null);
    }


    const allWalletAccounts = computed(() => {
        let walletAccounts = store.getters.walletAccounts;
        const totalAmount = walletAccounts.reduce((sum, item) => sum + item.balance, 0);
        walletAccounts.unshift({id: null, name: 'Все счета', balance: totalAmount, bgColor: '#9E9E9E'})
        return walletAccounts;
    })

    return {
        setTransaction,
        setTransactionForEdit,
        resetTransaction,
        allWalletAccounts
    }
}