const TOKEN_KEY = 'Authorization';
const REFRESH_TOKEN = 'Refresh'
const EXPIRE_DATE = 'Expire'

const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },
  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  },
  setToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
  },
  setRefreshToken(token) {
    localStorage.setItem(REFRESH_TOKEN, token);
  },
  setExpireDate(date) {
    localStorage.setItem(EXPIRE_DATE, date);
  },
  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },
  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN);
  },

};


export default TokenService;
